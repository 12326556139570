@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

* {
	box-sizing: border-box;
	padding: 0;
	margin: 0;
}

html {
	font-size: 62.5%;
	scroll-behavior: smooth;
}

body {
	margin: 0;
	background-color: #22252b;
	color: #fff;
	font-family: 'Montserrat', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	scroll-behavior: smooth;
}

code {
	font-family: 'Montserrat', sans-serif;
}

button,
input,
label,
textarea {
	font-family: 'Montserrat', sans-serif;
}
.footer a {
	color: inherit; /* Dziedzicz kolor z rodzica */
	text-decoration: none; /* Usuń podkreślenie */
}

a {
	text-decoration: none;
}
.content {
	text-align: center;
	margin-bottom: 30px;
}
.header {
	font-size: 30px;
}

.img-body img {
	display: flex;
	margin: 0 auto;
	height: 40vh;
	object-fit: cover;
	width: 100%;
	height: 100%;
	border-radius: 2em;
	cursor: pointer;
}

.gallery-title {
	padding-top: 4em;
	text-align: center;
	margin-bottom: 2em;
}

.img-body {
	max-width: 400px;
	aspect-ratio: 1/1;
	outline-color: red;
	display: inline-block;
	padding: 0 2em;
}

.container {
	margin: 0 auto;
	width: 100%;
	border-color: white;
}

.slider-container {
	width: 100%;
	margin: auto;
}

.slider-wrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%; /* Upewnij się, że kontener ma odpowiednią wysokość */
}

.slick-slide div {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
}

.wrapper {
	max-width: 1200px;
	width: 100%;
	margin: 0 auto;
}

.nav {
	position: fixed;
	z-index: 1001;
	width: 100%;
	padding: 2em 3em;
	color: #e0b96e;
	background-color: #15171a;
}

h1 {
	font-weight: 800;
	font-size: 3rem;
	color: #e0b96e;
}

.nav-items {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.nav-details {
	display: none;
	width: 100%;
	justify-content: space-between;
	align-items: center;
}

.nav-details-mobile {
	position: absolute;
	margin: 0 auto;
	left: 50%;
	transform: translateX(-50%);
	display: flex;
	flex-direction: column;
	margin-top: 40em;
	background-color: #15171a;
	border-radius: 3em;
	box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
	max-height: 0;
	overflow: hidden;
	transition: all 0.3s ease-out;
}

.nav-details-mobile.open {
	padding: 2em 6em;
	max-height: 300px;
}

.nav-details-mobile a {
	padding: 1em;
}

.nav-details-mobile .nav-details a {
	display: none;
}

.nav-items a {
	color: #fff;
	font-size: 1.8rem;
	cursor: pointer;
	text-decoration: none;
}

.desktop-h1 {
	display: none;
}

.nav-items svg {
	font-size: 3rem;
	cursor: pointer;
}

.hero-img {
	position: absolute;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	background-image: url('./images/2639139-kitchen-4k-high-quality-wallpaper-hd.jpg');
	background-position: 50% 40%;
	overflow: hidden;
	background-size: cover;
	background-repeat: no-repeat;
	opacity: 0.05;
}

.header-area {
	position: relative;
	overflow: hidden;
	height: 75vh;
}

.details-area {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

.header-text {
	position: absolute;
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	z-index: 100;
	margin-top: 2.5em;
}

h2 {
	/* font-size: 2rem; */
	padding: 0 1em;
	font-size: 3.5rem;
	text-align: center;
	font-weight: 800;
}

.header-info {
	font-size: 1.6rem;
	padding: 2em 0;
	text-align: center;
	font-weight: 200;
}

.header-info p:last-child {
	padding-top: 0.3em;
}

.header-btn {
	border: none;
	color: #000;
	font-size: 2rem;
	font-weight: 700;
	padding: 0.6em 2em;
	border-radius: 5em;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	background-color: #e0b96e;
}

.header-btn svg {
	margin-left: 0.5em;
	scale: 1.1;
}

.header-btn:hover {
	background-color: #ffffff;
	transition: all 0.3s;
}

.summary-section {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 5em 0;
}

.summary {
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	width: 350px;
}

.summary-experience {
	padding: 1em;
	/* border: 0.6em solid #595959; */
}

.summary-experience::before,
.summary-experience::after {
	content: '';
	position: absolute;
	width: 2em;
	height: 2em;
}

.summary-experience::before {
	margin: -5.5em 0em 0em -15.5em;
	border-top: 0.5em solid #595959;
	border-left: 0.5em solid #595959;
}
.summary-experience::after {
	margin: 7.5em 0em 0em 15.5em;
	border-bottom: 0.5em solid #595959;
	border-right: 0.5em solid #595959;
}

.summary-experience p:first-child {
	font-size: 4.5rem;
	font-weight: 800;
	color: #e0b96e;
}

.summary-experience p:last-child {
	text-transform: uppercase;
	font-size: 1.45rem;
	font-weight: 600;
	margin-top: -0.4em;
}

.summary-info {
	font-size: 2rem;
	text-align: center;
	padding: 2em 0;
}

.summary-info p:nth-child(1) {
	font-weight: 700;
}
.summary-info p:nth-child(2) {
	font-weight: 500;
	padding: 0.15em 0;
}
.summary-info p:nth-child(3) {
	font-weight: 200;
}

.summary-description {
	font-size: 1.8rem;
	padding: 0 2em;
	text-align: center;
	color: #9f9f9f;
}

.gallery-container {
	width: 100%;
	overflow-x: auto;
	white-space: nowrap;
	padding: 10px 0;
	cursor: grab;
}

.gallery-container:active {
	cursor: grabbing;
}

.gallery {
	display: flex;
	align-items: center;
}

.gallery-item {
	display: inline-block;
	margin-right: 10px;
}

.gallery-item img {
	display: block;
	max-height: 200px;
	width: auto;
	border-radius: 8px;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* .gallery {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 40em;
	overflow: hidden;
}

.gallery-box {
	cursor: pointer;
	margin: 0 1em;
}

.gallery-img {
	position: relative;
	width: 20em;
	height: 30em;
	overflow: hidden;
}

.gallery-img img {
	position: absolute;
	width: 100%;
	height: 100%;
	border-radius: 2em;
	opacity: 0.2;
} */

.details-section {
	padding: 5em 3em;
}

.details-main p {
	font-size: 2.2rem;
	color: #e0b96e;
	font-weight: 300;
}

.details-main h3 {
	font-size: 2.4rem;
	font-weight: 600;
}

.detail-circle {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 10em;
	height: 10em;
	border-radius: 50%;
	background-color: #22252b;
	z-index: 1000;
}

.detail-circle svg {
	font-size: 5rem;
	color: #e0b96e;
}

.detail {
	display: flex;
	flex-direction: column;
	justify-content: center;
	text-align: center;
	align-items: center;
	margin: 3.5em 0;
	background-color: #292e35;
	padding: 2em;
	border-radius: 2em;
}

.detail-title {
	text-transform: uppercase;
	font-size: 1.8rem;
	margin: 0.7em 0;
	color: #e0b96e;
}

.detail-description {
	font-size: 1.6rem;
	/* max-width: 200px; */
	font-weight: 200;
	color: #ffffffd5;
}

.detail-description p {
	padding: 0.4em;
}

.footer {
	padding: 2em 3em;
	background-color: #15171a;
}

.footer-contact {
	margin: 3em 0;
}

.contact {
	font-size: 1.8rem;
}

.contact-title {
	display: flex;
	align-items: center;
}

.contact-title p,
.contact-title svg {
	color: #e0b96e;
	font-weight: bold;
}

.footer-contact .contact:nth-child(2) {
	margin: 1em 0;
}

.footer-social svg {
	font-size: 3rem;
	color: #e0b96e;
	cursor: pointer;
}

.footer-social svg:last-child {
	margin-left: 0.2em;
}

.contact p {
	font-weight: 200;
}

.contact-title p {
	margin-left: 0.5em;
	font-weight: 500;
}

.form {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 3em;
	margin-bottom: 10em;
	margin-top: 0;
	padding: 3em 4em;
	border-radius: 2em;
	background-color: #15171a;
}

.form-text p {
	font-size: 1.7rem;
	font-weight: 200;
	padding: 1em 0;
}

h5 {
	color: #e0b96e;
	font-size: 3.5rem;
	font-weight: 800;
}

.contact-box {
	margin: 1.5em 0;
	display: flex;
	flex-direction: column;
}

.contact-boxes {
	width: 100%;
}

label {
	color: #979797;
	font-size: 1.6rem;
	margin-left: 0.25em;
	margin-bottom: 0.25em;
}

input,
textarea {
	background: none;
	border: 2px solid #1e2126;
	border-radius: 2em;
	padding: 0.7em;
	font-size: 1.4rem;
	color: #969696;
}

textarea {
	max-width: 100%;
}

.send {
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #e0b96e;
	color: #000;
	font-weight: 800;
	padding: 0.5em 1.6em;
	font-size: 1.8rem;
	border: none;
	border-radius: 2em;
	margin-top: 1em;
	cursor: pointer;
}

.send svg {
	margin-left: 0.5em;
}

.send:hover {
	background-color: #ffffff;
	transition: all 0.3s;
}

.gallery-wrapper {
	width: 100%;
	display: grid;
	gap: 3em;
	padding: 0 2em;
}

.image-container {
	overflow: hidden;
}

.gallery-image {
	width: 100%;
	height: auto;
	max-height: 470px;
	border-radius: 2em;
	cursor: pointer;
}

.modal {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.6);
	display: flex;
	justify-content: center;
	align-items: center;
}

.modal-content {
	position: relative;
	max-width: 50vh;
	max-height: 50vh;
	padding: 0 2em;
}

.modal-image {
	width: 100%;
	height: auto;
	display: block;
	border-radius: 2em;
	max-height: 470px;
}

.modal-close {
	position: absolute;
	top: 10px;
	right: 30px;
	background: white;
	border: none;
	cursor: pointer;
	font-size: 20px;
	padding: 5px 10px;
	border-radius: 2em;
}

.page-info {
	font-size: 1.6rem;
	padding: 0 1em;
}

.pagination {
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 20px 0;
}

.pagination button {
	padding: 0.3em 1em;
	border: 0;
	border-radius: 2em;
	font-size: 1.6rem;
	cursor: pointer;
	font-weight: 500;
}

@media (min-width: 500px) {
	.summary-description {
		max-width: 400px;
	}

	.detail {
		max-width: 400px;
	}
}

@media (min-width: 768px) {

	.modal-image {
		max-height: 600px;
	}

	.gallery-wrapper {
		grid-template-columns: repeat(2, 1fr);
	}
}

@media (min-width: 1000px) {
	h2 {
		width: 800px;
		font-size: 4rem;
	}

	.gallery-wrapper {
		grid-template-columns: repeat(3, 1fr);
	}

	.gallery-title {
		text-align: center;
		width: auto;
		margin-bottom: 2em;
	}

	.summary-section {
		justify-content: space-around;
		flex-direction: row;
	}

	.header-info {
		font-size: 2rem;
	}

	.summary-description {
		text-align: left;
		font-size: 1.6rem;
	}

	.details-area {
		flex-direction: row;
		justify-content: space-between;
		align-items: flex-start;
	}

	/* dfadada */
	.detail-circle {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 10em;
		height: 10em;
		border-radius: 50%;
		background-color: #22252b;
	}

	.detail-circle svg {
		font-size: 5rem;
		color: #e0b96e;
	}

	.detail {
		display: flex;
		flex-direction: column;
		justify-content: center;
		text-align: left;
		align-items: center;
		margin: 5.5em 0;
		padding: 3em;
		border-radius: 2em;
	}

	.detail-title {
		text-transform: uppercase;
		font-size: 1.8rem;
		margin: 0.7em 0;
		color: #e0b96e;
	}

	.detail-description {
		font-size: 1.6rem;
		max-width: 250px;
	}

	.details-main h3 {
		font-size: 2.7rem;
		font-weight: 600;
		max-width: 60%;
	}

	.details-section {
		padding: 5em 3em;
	}

	form {
		max-width: 800px;
	}

	.form-wrapper {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.footer-area {
		display: flex;
		justify-content: space-around;
	}

	.footer-contact {
		margin-top: 0;
	}

	.nav-items a {
		transition: color 0.3s;
	}

	.nav-items a:hover {
		color: #e0b96e;
	}

	.footer-area {
		padding: 2em 0;
	}

	.burger-menu {
		display: none;
	}
	.nav-details {
		display: flex;
	}
	.nav-details a {
		display: flex;
	}

	.nav-details-mobile {
		display: none;
	}
	.mobile-h1 {
		display: none;
	}

	.desktop-h1 {
		display: block;
	}
}

.isloading {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
	background: rgba(0, 0, 0, 0.5);
}

.popuptext {
	background: #ffffff;
	padding: 2em;
	text-align: center;
	color: #000;
	font-size: 2rem;
}

.popuptext .bottom {
	margin-bottom: 4em;
}
